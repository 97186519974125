import {createReducer, on} from '@ngrx/store';
import {INITIAL_LOAD_STATUS_SUBSTATE, LoadStatusSubstate} from '@store/store.types';
import {FAILED, LOADED, LOADING} from '@store/common/common.types';
import {AccountActions} from './account.actions';
import {PatronBalanceTransaction} from './account.types';

export interface AccountState {
  balanceTransactions: LoadStatusSubstate & {
    data: PatronBalanceTransaction[];
    includeAllTransactions: boolean;
  };
}

export const INITIAL_ACCOUNT_STATE: AccountState = {
  balanceTransactions: {
    ...INITIAL_LOAD_STATUS_SUBSTATE,
    data: [],
    includeAllTransactions: false
  },
};

export const accountState = createReducer(
  INITIAL_ACCOUNT_STATE,

  on(AccountActions.loadBalanceTransactions, state => ({
    ...state,
    balanceTransactions: {
      ...state.balanceTransactions,
      loadStatus: LOADING
    }
  })),
  on(AccountActions.loadBalanceTransactionsSuccess, (state, {listData: {data}}) => ({
    ...state,
    balanceTransactions: {
      ...state.balanceTransactions,
      loadStatus: LOADED,
      data,
    }
  })),
  on(AccountActions.loadBalanceTransactionsFailure, state => ({
    ...state,
    balanceTransactions: {
      ...INITIAL_ACCOUNT_STATE.balanceTransactions,
      loadStatus: FAILED
    }
  })),
  on(AccountActions.toggleIncludeAllBalanceTransactions, state => ({
    ...state,
    balanceTransactions: {
      ...state.balanceTransactions,
      includeAllTransactions: !state.balanceTransactions.includeAllTransactions
    }
  })),
);
