import {HttpClient, HttpParams} from '@angular/common/http';
import {inject, Injectable} from '@angular/core';
import {Params} from '@angular/router';
import {map, Observable} from 'rxjs';
import {Environment} from '@raven';
import {buildQueryParamsFrom, extractListData} from '@store/common/list-data.helpers';
import {ListData, ResponseBase} from '@store/common/common.types';
import {PatronBalanceTransaction} from '@store/account/account.types';

@Injectable({providedIn: 'root'})
export class AccountService {
  private readonly http: HttpClient = inject(HttpClient);
  private readonly environment: Environment = inject(Environment);

  public loadBalanceTransactions(queryParams: Params, includeAllTransactions: boolean): Observable<ListData<PatronBalanceTransaction>> {
    const url = `${this.environment.apiUrl}/patrons/v3/patrons/account/transactions`;
    const params: HttpParams = buildQueryParamsFrom({
      ...queryParams,
      onlyOutstanding: !includeAllTransactions
    });
    return this.http.get<ResponseBase<PatronBalanceTransaction>>(url, {params}).pipe(
      map(response => extractListData<PatronBalanceTransaction>(response))
    );
  }

}
