<div class="my-account-nav">
  <div class="mat-headline-4">My Account</div>
  <a class="my-account-nav-link" routerLink="/dashboard" routerLinkActive="active-route">
    <span>Dashboard</span>
    <mat-icon>arrow_forward</mat-icon>
  </a>
  <mat-divider/>
  <a class="my-account-nav-link" routerLink="/checkouts" routerLinkActive="active-route">
    <span>Checked Out</span>
    <mat-icon>arrow_forward</mat-icon>
  </a>
  <mat-divider/>
  <a class="my-account-nav-link" routerLink="/holds" routerLinkActive="active-route">
    <span>On Hold</span>
    <mat-icon>arrow_forward</mat-icon>
  </a>
  <mat-divider/>
  <a class="my-account-nav-link" routerLink="/profile/balance" routerLinkActive="active-route">
    <span>Balance</span>
    <mat-icon>arrow_forward</mat-icon>
  </a>
  <mat-divider/>
  <a class="my-account-nav-link" routerLink="/patron-item-requests-v2" routerLinkActive="active-route">
    <span>Requests</span>
    <mat-icon>arrow_forward</mat-icon>
  </a>
  <mat-divider/>
  <a class="my-account-nav-link" routerLink="/profile/account" routerLinkActive="active-route">
    <span>My Profile</span>
    <mat-icon>arrow_forward</mat-icon>
  </a>
  <mat-divider/>
  <a class="my-account-nav-link" routerLink="/favorites" routerLinkActive="active-route">
    <span>Favorites</span>
    <mat-icon>arrow_forward</mat-icon>
  </a>
</div>
