import {DateTimeIsoString} from '@store/common/common.types';

export const ACCOUNT_FEATURE_KEY = 'accounting';

export interface PatronBalanceTransaction {
  readonly id: number;
  readonly patronId: number;
  readonly branchId: number;
  readonly type: PatronBalanceTransactionType;
  readonly amount: number;
  readonly opacNote: string;
  readonly created: DateTimeIsoString;

  readonly itemTitle: string;
  readonly branchName: string;
  readonly balanceLines: PatronBalanceLine[];

  readonly amountOutstanding: number;
  readonly name: string;
}

export type PatronBalanceTransactionType =
  'PAY_FROM_EXTERNAL' | 'PAY_FROM_WALLET' | 'ADD_CHARGE' | 'WAIVE_CHARGE' | 'ADD_CREDIT_TO_WALLET' | 'REFUND_FROM_WALLET';

export interface PatronBalanceLine {
  readonly id: number;
  readonly transactionId: number;
  readonly amount: number;
  readonly amountOutstanding: number;
  readonly target: PatronBalanceLineTarget;
  readonly created: DateTimeIsoString;
  readonly name: string;
}

export type PatronBalanceLineTarget =
  'BALANCE' | 'WALLET' | 'DEPOSIT';
