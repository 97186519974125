import {createActionGroup, emptyProps} from '@ngrx/store';
import {ListData} from '@store/common/common.types';
import {ACCOUNT_FEATURE_KEY, PatronBalanceTransaction} from '@store/account/account.types';

export const AccountActions = createActionGroup({
  source: ACCOUNT_FEATURE_KEY,
  events: {
    'Load Balance Transactions': emptyProps(),
    'Load Balance Transactions Success': (listData: ListData<PatronBalanceTransaction>) => ({listData}),
    'Load Balance Transactions Failure': (error: unknown) => ({error}),
    'Toggle Include All Balance Transactions': emptyProps(),
  }
});
