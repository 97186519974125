import {PatronBalanceLine, PatronBalanceTransaction} from './account.types'

export const FALLBACK_PATRON_BALANCE_TRANSACTION: PatronBalanceTransaction = {
  id: 0,
  patronId: 0,
  branchId: 0,
  type: 'PAY_FROM_EXTERNAL',
  amount: 0,
  opacNote: '',
  created: '',
  itemTitle: '',
  branchName: '',
  balanceLines: [],
  amountOutstanding: 0,
  name: ''
}

export const FALLBACK_PATRON_BALANCE_LINE: PatronBalanceLine = {
  id: 0,
  transactionId: 0,
  amount: 0,
  amountOutstanding: 0,
  target: 'BALANCE',
  created: '',
  name: ''
}
