import {ChangeDetectionStrategy, Component, Input, TrackByFunction} from '@angular/core';
import {PatronBalanceLine, PatronBalanceTransaction} from '@store/account/account.types';
import {trackById} from '@store/common/track-by.helpers';
import {AccountSelectors} from '@store/store.selectors';


@Component({
  selector: 'rn-expanded-transaction-names',
  template: `
    <div class="expansion-row-content" rnTruncate
         *ngFor="let balanceLine of transactionSelectors.sortedBalanceLinesFor(transaction) | select; trackBy:trackById">
      {{ transactionLineSelectors.nameFor(balanceLine) | select }}
      ({{ transactionLineSelectors.targetFor(balanceLine) | select | format:'TITLE_CASE' }})
    </div>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ExpandedTransactionNamesComponent {
  @Input() transaction: PatronBalanceTransaction;

  public readonly transactionSelectors = AccountSelectors.BalanceTransactions;
  public readonly transactionLineSelectors = AccountSelectors.BalanceTransactionLines;
  public readonly trackById: TrackByFunction<PatronBalanceLine> = trackById;
}
