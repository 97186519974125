import {ChangeDetectionStrategy, Component} from '@angular/core';
import {trackByProperty} from '@store/common/track-by.helpers';
import {RouterSelectors} from '@store/store.selectors';

@Component({
  selector: 'rn-profile',
  templateUrl: './profile.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styles: [`
      .warning-banner {
          box-sizing: border-box;
      }

      .warning-banner,
      nav,
      div.content-container {
          max-width: var(--content-width);
          margin: 0 auto 0 auto;
      }

      .tab-spacer {
          height: 32px;
      }
  `],
})
export class ProfileComponent {
  public readonly routerSelectors = RouterSelectors;
  public readonly trackByName = trackByProperty('name');
  public readonly tabs = [
    {
      name: 'PROFILE_ACCOUNT',
      link: '/profile/account',
      label: 'Account'
    },
    {
      name: 'PROFILE_BALANCE',
      link: '/profile/balance',
      label: 'Balance & Payments'
    },
    {
      name: 'NOTIFICATION_PREFERENCES',
      link: '/profile/notification-preferences',
      label: 'Notification Preferences'
    }
  ];
}
