import {inject, Injectable} from '@angular/core';
import {Actions, createEffect} from '@ngrx/effects';
import {filter, map} from 'rxjs';
import {PaginationActions} from '@store/pagination/pagination.actions';
import {isDefined, isObject} from '@store/common/typing.helpers';
import {isListData} from '@store/common/common.types';
import {RouterActions} from '@store/router/router.actions';

@Injectable({providedIn: 'root'})
export class PaginationEffects {

  private readonly actions: Actions = inject(Actions);

  public updateFromAppropriateActions = createEffect(() =>
    this.actions.pipe(
      map(action => isObject(action) && isListData(action.listData) ? action.listData.paginationInfo : undefined),
      filter(isDefined),
      map(paginationInfo => paginationInfo.page > 1 &&
        paginationInfo.page > Math.ceil(paginationInfo.totalCount / paginationInfo.pageSize) // If this is true, our page number is out of bounds...
          ? RouterActions.updatePaginationParams(1, paginationInfo.pageSize)                 // ...so reset to page 1...
          : PaginationActions.update(paginationInfo)                                         // ...otherwise we're good, so update the store with this data.
      )
    )
  );
}
