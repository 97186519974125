import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest,} from '@angular/common/http';
import {Observable} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {NotificationService, RoutesService} from '@raven';

@Injectable({
  providedIn: 'root',
})
export class HttpErrorInterceptor implements HttpInterceptor {
  constructor(
    private notificationService: NotificationService,
    public routesService: RoutesService
  ) {
  }

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      catchError((error: unknown) => {
        if (error instanceof HttpErrorResponse) {
          // Route errors to appropriate landing page - only include error codes that don't need to be handled in the service layer
          if (error.status === 400) {
            // BAD REQUEST
            this.notificationService.showSnackbarError(
              'Unable to complete request - Error code 400'
            );
          } else if (error.status === 401) {
            // UNAUTHORIZED
            this.routesService.goTo401().then();
          } else if (error.status === 404) {
            // NOT FOUND
            this.routesService.goTo404().then();
          } else if (error.status === 405) {
            // METHOD NOT ALLOWED
            this.routesService.goTo404().then();
          } else if (error.status === 415) {
            // UNSUPPORTED MEDIA TYPE
            this.notificationService.showSnackbarError(
              'Unsupported media type - contact system administrator'
            );
          } else if (error.status === 500) {
            // INTERNAL SERVER ERROR
            this.routesService.goTo500().then();
          }
          return next.handle(request);
        }
      })
    );
  }
}
