import {NgModule} from '@angular/core';
import {AccountComponent} from './account/account.component';
import {ProfileComponent} from './profile.component';
import {NotificationPreferencesComponent} from './notification-preferences/notification-preferences.component';
import {PasswordUpdateDialog} from './account/dialogs/password-update-dialog';
import {PinUpdateDialog} from './account/dialogs/pin-update-dialog';
import {DeleteAccountDialogComponent} from './account/dialogs/delete-account.dialog';
import {SaveHistoryDialogComponent} from './account/dialogs/save-history.dialog';
import {AddressChangeDialog} from './account/dialogs/address-change.dialog';
import {SharedModule} from '../shared/shared.module';
import {EmailUpdateDialog} from './account/dialogs/email-update-dialog';
import {RvFormsModule} from '../shared/form-fields/rv-forms.module';
import {DeleteHistoryDialogComponent} from './account/dialogs/delete-history.dialog';
import {BalanceAndPaymentsComponent} from './balance-and-payments/balance-and-payments.component';
import {TableModule} from '../../table/table.module';
import {ExpandedTransactionNamesComponent} from './balance-and-payments/expanded-transaction-names/expanded-transaction-names.component';
import {ExpandedTransactionAmountsComponent} from './balance-and-payments/expanded-transaction-amounts/expanded-transaction-amounts.component';
import {ExpandedTransactionOpacNoteComponent} from './balance-and-payments/expanded-transaction-opac-note/expanded-transaction-opac-note.component';
import {
  TransactionCombinedNameItemTitleComponent
} from './balance-and-payments/transaction-combined-name-item-title/transaction-combined-name-item-title.component';

@NgModule({
  imports: [
    SharedModule,
    RvFormsModule,
    TableModule,
  ],
  declarations: [
    ProfileComponent,
    AccountComponent,
    BalanceAndPaymentsComponent,
    NotificationPreferencesComponent,

    EmailUpdateDialog,
    PasswordUpdateDialog,
    PinUpdateDialog,
    AddressChangeDialog,
    DeleteAccountDialogComponent,
    SaveHistoryDialogComponent,
    DeleteHistoryDialogComponent,
    TransactionCombinedNameItemTitleComponent,
    ExpandedTransactionNamesComponent,
    ExpandedTransactionAmountsComponent,
    ExpandedTransactionOpacNoteComponent,
  ],
})
export class ProfileModule {
}
