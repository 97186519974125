import {MxSelector} from '@store/store.types';
import {createFeatureSelector, createSelector} from '@ngrx/store';
import {PaginationState} from './pagination.state';
import {PAGINATION_FEATURE_KEY} from '@store/pagination/pagination.types';

export const entireState: MxSelector<PaginationState> = createFeatureSelector(PAGINATION_FEATURE_KEY);

export const page: MxSelector<number> = createSelector(
  entireState,
  ({page}) => page
);

export const zeroBasedPage: MxSelector<number> = createSelector(
  page,
  _page => _page - 1
);

export const pageSize: MxSelector<number> = createSelector(
  entireState,
  ({pageSize}) => pageSize
);

export const totalCount: MxSelector<number> = createSelector(
  entireState,
  ({totalCount}) => totalCount
);

export const hasData: MxSelector<boolean> = createSelector(
  totalCount,
  count => count > 0
);

export const onFinalPage: MxSelector<boolean> = createSelector(
  page,
  totalCount,
  pageSize,
  (_page, _totalCount, _pageSize) => _totalCount === 0 || _page === Math.ceil(_totalCount / _pageSize)
);
