import {NgModule} from '@angular/core';
import {PatronCheckoutsComponent} from './patron-checkouts.component';
import {ClaimReturnedDialog} from './dialogs/claims-dialog';
import {PatronCheckoutStatusComponent} from './patron-checkout-status.component';
import {CheckoutStatusFilterComponent} from './components/checkouts-filter-component/checkout-status-filter.component';
import {SharedModule} from '../shared/shared.module';
import {RvFormsModule} from '../shared/form-fields/rv-forms.module';
import {TableModule} from '../../table/table.module';
import {PatronCheckoutsActionsComponent} from './patron-checkouts-actions.component';
import {BorrowingHistoryComponent} from './borrowing-history/borrowing-history.component';
import {ClaimsDetailsDialog} from './claim-details/claims-details-dialog.component';
import {CheckoutClaimTypeComponent} from './checkout-claim-type.component';

@NgModule({
  imports: [
    SharedModule,
    RvFormsModule,
    TableModule,
  ],
  exports: [PatronCheckoutsComponent],
  declarations: [
    PatronCheckoutsComponent,
    PatronCheckoutStatusComponent,
    ClaimReturnedDialog,
    CheckoutStatusFilterComponent,
    PatronCheckoutsActionsComponent,
    BorrowingHistoryComponent,
    ClaimsDetailsDialog,
    CheckoutClaimTypeComponent,
  ],
})
export class CheckoutsModule {
}
