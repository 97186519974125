import {MxSelector, MxSelectorCreator, NO_NAME_PREFIX} from '@store/store.types';
import {createFeatureSelector, createSelector} from '@ngrx/store';
import {generateIndependentSelectorCreatorsFor, generateLoadStatusSelectorsFor} from '@store/store.selector-generators';
import {AccountState} from './account.state';
import {ACCOUNT_FEATURE_KEY, PatronBalanceLine, PatronBalanceTransaction} from './account.types';
import {FALLBACK_PATRON_BALANCE_LINE, FALLBACK_PATRON_BALANCE_TRANSACTION} from '@store/account/account.fallback';
import {isPopulatedString} from '@store/common/typing.helpers';

export const entireState: MxSelector<AccountState> = createFeatureSelector(ACCOUNT_FEATURE_KEY);

export namespace BalanceTransactions {
  const substate: MxSelector<AccountState['balanceTransactions']> = createSelector(
    entireState,
    ({balanceTransactions}) => balanceTransactions
  );

  export const transactions: MxSelector<PatronBalanceTransaction[]> = createSelector(
    substate,
    ({data}) => data
  );

  export const {
    loading,
    loaded,
    awaitingFirstLoad,
    awaitingAnyLoad
  } = generateLoadStatusSelectorsFor(substate);

  export const includeAllTransactions: MxSelector<boolean> = createSelector(
    substate,
    ({includeAllTransactions}) => includeAllTransactions
  );

  export const {
    createdFor,
    idFor,
    nameFor,
    itemTitleFor,
    amountFor,
    amountOutstandingFor,
    typeFor,
    balanceLinesFor,
    opacNoteFor,
  } = generateIndependentSelectorCreatorsFor(FALLBACK_PATRON_BALANCE_TRANSACTION, NO_NAME_PREFIX);

  export const hasBalanceReducingTransactionTypeFor: MxSelectorCreator<[PatronBalanceTransaction], boolean> =
    balanceTransaction => createSelector(
      typeFor(balanceTransaction),
      type => ['PAY_FROM_EXTERNAL', 'PAY_FROM_WALLET', 'WAIVE_CHARGE', 'ADD_CREDIT_TO_WALLET'].includes(type)
    );

  export const amountOutstandingIsNegativeFor: MxSelectorCreator<[PatronBalanceTransaction], boolean> =
    balanceTransaction => createSelector(
      amountOutstandingFor(balanceTransaction),
      amount => amount < 0
    );

  export const showAmountsExpansionFor: MxSelectorCreator<[PatronBalanceTransaction], boolean> =
    balanceTransaction => createSelector(
      balanceLinesFor(balanceTransaction),
      typeFor(balanceTransaction),
      (balanceLines, type) =>
        balanceLines.length > 1 && type === 'PAY_FROM_EXTERNAL'
    );

  export const showDetailsExpansionFor: MxSelectorCreator<[PatronBalanceTransaction], boolean> =
    balanceTransaction => createSelector(
      typeFor(balanceTransaction),
      opacNoteFor(balanceTransaction),
      (type, opacNote) =>
        type === 'ADD_CHARGE' && isPopulatedString(opacNote)
    );

  export const showExpansionFor: MxSelectorCreator<[PatronBalanceTransaction], boolean> =
    balanceTransaction => createSelector(
      showAmountsExpansionFor(balanceTransaction),
      showDetailsExpansionFor(balanceTransaction),
      (showAmounts, showDetails) => showAmounts || showDetails
    );

  export const sortedBalanceLinesFor: MxSelectorCreator<[PatronBalanceTransaction], PatronBalanceLine[]> =
    balanceTransaction => createSelector(
      balanceLinesFor(balanceTransaction),
      (balanceLines) => [...balanceLines].sort((a, b) => a.target.localeCompare(b.target))
    );

} // namespace BalanceTransactions

export namespace BalanceTransactionLines {
  export const {
    nameFor,
    targetFor,
    amountFor,
  } = generateIndependentSelectorCreatorsFor(FALLBACK_PATRON_BALANCE_LINE, NO_NAME_PREFIX);

} // namespace BalanceTransactionLines
