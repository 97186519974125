import {CheckoutSession, Patron, PatronAccountStatus, PatronCategory, PatronRegistrationValue, PatronRule, PatronStatistics} from './patron.types';
import {State} from '@store/common/common.types';

export const FALLBACK_PATRON_CATEGORY: PatronCategory = {
  id: 0,
  organizationId: 0,
  name: '',
  chipColor: '',
  guarantorRequired: false,
  dobRequired: false,
  boundaryRestricted: false,
  expireAtMaxAge: false,
  expirationDate: '',
  minAge: 0,
  maxAge: 0,
  registrationTerm: 0,
  registrationFee: 0,
  renewalFee: 0,
  holdFee: 0,
  overdueNotice: false,
  showLostItems: false,
  active: false,
  created: '',
  modified: ''
};

export const FALLBACK_PATRON_RULE: PatronRule = {
  id: 0,
  organizationId: 0,
  patronId: 0,
  patronCategoryId: 0,
  maxItemsOut: 0,
  maxItemsOutBorrowBlocked: false,
  maxItemsOutRenewalBlocked: false,
  maxItemsOutHoldBlocked: false,
  maxItemsOutBlockNote: '',
  maxItemsLost: 0,
  maxItemsLostBorrowBlocked: false,
  maxItemsLostRenewalBlocked: false,
  maxItemsLostHoldBlocked: false,
  maxItemsLostBlockNote: '',
  maxItemsOverdue: 0,
  maxItemsOverdueBorrowBlocked: false,
  maxItemsOverdueRenewalBlocked: false,
  maxItemsOverdueHoldBlocked: false,
  maxItemsOverdueBlockNote: '',
  maxFeeBalance: 0,
  maxFeeBalanceBorrowBlocked: false,
  maxFeeBalanceRenewalBlocked: false,
  maxFeeBalanceHoldBlocked: false,
  maxFeeBalanceBlockNote: '',
  maxHoldsPlaced: 0,
  maxPausedHoldsPlaced: 0,
  expiredCardHoldBlocked: false,
  holdsLimitOnePerCatalogPerPatron: false,
  maxHoldsNote: '',
  active: false,
  created: '',
  modified: '',

  patronCategory: FALLBACK_PATRON_CATEGORY
};

export const FALLBACK_PATRON_STATISTICS: PatronStatistics = {
  openHolds: 0,
  openLoans: 0,
  claimedReturnedCount: 0,
  heldItems: 0,
  overdueItems: 0
};

export const FALLBACK_CHECKOUT_SESSION: CheckoutSession = {
  id: 0,
  patronId: 0,
  pdfReceiptServingUrl: '',
  pdfFileUri: '',
  circulationItemCount: 0,
  active: false,
  created: '',
  modified: '',
  checkoutSessionCirculationItems: [],
}

export const FALLBACK_PATRON: Patron = {
  id: 0,

  organizationId: 0,
  googleUid: '',
  branchId: 0,
  patronCategoryId: 0,
  barcode: '',
  pinCode: '',
  preferredName: '',
  email: '',
  notificationEmail: '',
  password: '',
  firstName: '',
  middleName: '',
  lastName: '',
  preferredLanguage: '',
  initials: '',
  salutation: '',
  gender: '',
  dateOfBirth: '',
  accountExpiration: '',
  lastLogin: '',
  timezone: '',

  phone1: '',
  phone1Type: 'HOME',

  address: '',
  address2: '',
  city: '',
  state: '',
  zipCode: '',
  country: '',

  emailVerified: false,
  pinGenerated: false,
  mobileNumberVerified: false,
  optInReadingHistory: false,
  unsubscribeAllNotifications: false,
  lookupAttempts: 0,

  active: false,
  created: '',
  modified: '',

  underBalanceLimit: true,

  category: FALLBACK_PATRON_CATEGORY,
  addresses: [],
  guarantors: [],
  checkoutSession: FALLBACK_CHECKOUT_SESSION,
  openHolds: 0,

  openLoans: 0,
  accountBalance: 0,
  patronRule: FALLBACK_PATRON_RULE,

  patronStatistics: FALLBACK_PATRON_STATISTICS
};

export const FALLBACK_REGISTRATION_VALUE: PatronRegistrationValue = {
  address: '',
  city: '',
  firstName: '',
  lastName: '',
  state: '' as State,
  zip: '',
  dateOfBirth: '',
  notifyEmail: false,
  notifyText: false,
  notificationEmail: '',
  mobilePhone: '',
  password: '',
  verificationUUID: '',
  branchId: 0,
}

export const FALLBACK_PATRON_ACCOUNT_STATUS: PatronAccountStatus = {
  accountExpiration: '',
  currentAccountBalance: 0,
  depositBalance: 0,
  walletBalance: 0,
  maximumAccountBalance: 0,
  minimumBalanceDue: 0,
  activeHoldsCount: 0,
  blocks: [],
  unresolvedCheckoutCount: 0,
  unresolvedItemRequestCount: 0,
}

