import {inject, Injectable} from '@angular/core';
import {Router} from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class RoutesService {
  private readonly router = inject(Router);

  public goToIndex(): Promise<boolean> {
    return this.router.navigate(['/']);
  }

  public goToCheckouts(): Promise<boolean> {
    return this.router.navigate(['/checkouts']);
  }

  public goToCatalogHome(): Promise<boolean> {
    return this.router.navigate(['/catalog']);
  }

  public goToCatalogRecords(): Promise<boolean> {
    return this.router.navigate(['/catalog-browse']);
  }

  public goToCatalogRecordDetail(itemId: number): Promise<boolean> {
    return this.router.navigate(['/catalog-item', itemId]);
  }

  public goToHolds(): Promise<boolean> {
    return this.router.navigate(['/holds']);
  }

  public goToProfile(): Promise<boolean> {
    return this.router.navigate(['/profile/account']);
  }

  public goToNotificationPreferences(): Promise<boolean> {
    return this.router.navigate(['/profile/notification-preferences']);
  }

  public goToPatronBilling(): Promise<boolean> {
    return this.router.navigate(['/profile/balance']);
  }

  public goToMyCard(): Promise<boolean> {
    return this.router.navigate(['/my-card']);
  }

  public openPDF(servingURL: string): Promise<boolean> {
    window.open(servingURL, '_blank');
    return Promise.resolve(true);
  }

  /* ********** ERROR PAGES ********** */

  public goTo401(): Promise<boolean> {
    return this.router.navigate(['/401']);
  }

  public goTo403(): Promise<boolean> {
    return this.router.navigate(['/403']);
  }

  public goTo404(): Promise<boolean> {
    return this.router.navigate(['/404']);
  }

  public goTo500(): Promise<boolean> {
    return this.router.navigate(['/500']);
  }
}
