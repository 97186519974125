<div class="summary-tab flex-row-wrap">
    <div class="left-column">
        <rn-expansion-panel *ngIf="metadata && metadata.summary">
            <h4 expansionHeader>Description</h4>
            <ng-container>
                <rn-show-more-section [maxHeight]="150">
                    <span #showMoreContent [innerHTML]="metadata.summary"></span>
                </rn-show-more-section>
            </ng-container>
        </rn-expansion-panel>
        <rn-expansion-panel *ngIf="catalogRecord && catalogRecord.marcDetails">
            <h4 expansionHeader>Item Details</h4>
            <ng-container *ngTemplateOutlet="detailsBody; context: {catalogRecord: catalogRecord}"></ng-container>
        </rn-expansion-panel>
    </div>
</div>

<ng-template #detailsBody let-catalogRecord="catalogRecord">
    <div class="details-body">
        <table class="details-table">
            <caption style="display: none;">Catalog Item Details</caption>
            <tr style="display: none;">
                <th>Detail Field</th>
                <th>Detail Value</th>
            </tr>
            <!-- Material Type Match -->
            <tr>
                <td>Format:</td>
                <td>{{ catalogRecord.materialType.description }}</td>
            </tr>
            <tr>
                <td class="title">Title:</td>
                <td>{{ catalogRecord.marcDetails.title }}</td>
            </tr>
            <tr>
                <td>Author:</td>
                <td><a [queryParams]="{filterLinkType: 'author', filterLinkValue: catalogRecord.marcDetails.author}"
                       routerLink="/catalog-browse">{{ catalogRecord.marcDetails.author }}</a>
                </td>
            </tr>
            <tr>
                <td>Edition:</td>
                <td>{{ catalogRecord.marcDetails.edition || 'Placeholder Edition' }}</td>
            </tr>
            <tr>
                <td>Publisher, Date:</td>
                <td *ngIf="!catalogRecord.marcDetails.publisher">Not available</td>
                <td *ngIf="catalogRecord.marcDetails.publisher">{{ catalogRecord.marcDetails.publisher }} {{ catalogRecord.marcDetails.publicationDate }}</td>
            </tr>
            <tr>
                <td>Description:</td>
                <td>{{ catalogRecord.marcDetails.physicalDescription || 'Not available' }}</td>
            </tr>
            <tr *ngFor="let s of catalogRecord.marcDetails.subjects; let i = index">
                <ng-container *ngIf="i === 0; else notFirstSubject">
                    <td>Subjects:</td>
                    <td><a [queryParams]="{filterLinkType: 'subject', filterLinkValue: s}" routerLink="/catalog-browse">{{ s }}</a></td>
                </ng-container>
                <ng-template #notFirstSubject>
                    <td></td>
                    <td><a [queryParams]="{filterLinkType: 'subject', filterLinkValue: s}" routerLink="/catalog-browse">{{ s }}</a></td>
                </ng-template>
            </tr>
            <tr *ngFor="let s of catalogRecord.marcDetails.genres; let i = index">
                <ng-container *ngIf="i === 0; else notFirstGenre">
                    <td>Genres:</td>
                    <td><a [queryParams]="{filterLinkType: 'genre', filterLinkValue: s}" routerLink="/catalog-browse">{{ s }}</a></td>
                </ng-container>
                <ng-template #notFirstGenre>
                    <td></td>
                    <td><a [queryParams]="{filterLinkType: 'genre', filterLinkValue: s}" routerLink="/catalog-browse">{{ s }}</a></td>
                </ng-template>
            </tr>
            <ng-container *ngIf="catalogRecord.marcDetails.identifiers">
                <tr *ngFor="let s of catalogRecord.marcDetails.identifiers['isbn']; let i = index">
                    <ng-container *ngIf="i === 0; else notFirstISBN">
                        <td>ISBN:</td>
                        <td>{{ s }}</td>
                    </ng-container>
                    <ng-template #notFirstISBN>
                        <td></td>
                        <td>{{ s }}</td>
                    </ng-template>
                </tr>
            </ng-container>
        </table>
    </div>
</ng-template>
