import {AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, inject, Input} from '@angular/core';
import {RowExpansionService} from './row-expansion.service';

@Component({
  selector: 'rn-expansion-toggle',
  template: `
    <button class="square-icon-button flex-row flex-center-center"
            (click)="onToggleExpansion()">
      <mat-icon>{{ isExpanded ? 'remove' : 'add' }}</mat-icon>
    </button>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ExpansionToggleComponent<RowObject> implements AfterViewInit {
  @Input() rowObject: RowObject;
  private readonly elementReference: ElementRef = inject(ElementRef);
  private readonly rowExpansionService: RowExpansionService<RowObject> = inject(RowExpansionService);
  private parentTableRow: HTMLTableRowElement;

  public ngAfterViewInit(): void {
    const parentTableRow: HTMLTableRowElement | null = (this.elementReference.nativeElement as HTMLElement).closest('tr');
    if (!parentTableRow) throw Error('ExpansionToggleComponent needs to be inside a <tr> element');
    this.parentTableRow = parentTableRow;
  }

  public get isExpanded(): boolean {
    return this.rowExpansionService.isExpanded(this.rowObject);
  }

  public onToggleExpansion(): void {
    this.rowExpansionService.toggleExpansionFor(this.rowObject, this.parentTableRow);
  }
}
