import {APP_INITIALIZER, ErrorHandler, NgModule} from '@angular/core';
import {AppComponent} from './app.component';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {environment} from '../environments/environment';
import {HttpErrorInterceptor} from './core/http/http-error.interceptor';
import {LoginComponent} from './core/login/login.component';
import {LogoutComponent} from './core/login/logout.component';
import {BrowserModule, DomSanitizer} from '@angular/platform-browser';
import {StoreDevtoolsModule} from '@ngrx/store-devtools';
import {StoreModule} from '@ngrx/store';
import {AppLayoutComponent} from './_layout/app-layout.component';
import {AppRoutingModule} from './app.routes';
import {IndexComponent} from './core/index/index.component';
import {RoutesModule} from './core/routes/routes.module';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE, MatDateFormats} from '@angular/material/core';
import {NonBreakingPipe} from './core/shared/pipes/non-breaking.pipe';
import {HoldsModule} from './core/holds/holds.module';
import {RegistrationModule} from './core/registration/registration.module';
import {CurrencyPipe, DatePipe, DecimalPipe, PercentPipe, TitleCasePipe, UpperCasePipe} from '@angular/common';
import {LandingComponent} from './core/login/landing.component';
import {AngularFireModule} from '@angular/fire/compat';
import {AngularFireAuthModule} from '@angular/fire/compat/auth';
import {AngularFireAuthGuardModule} from '@angular/fire/compat/auth-guard';
import {CheckoutsModule} from './core/patron-checkouts/checkouts.module';
import {ProfileModule} from './core/profile/profile.module';
import {CatalogModule} from './core/catalog/catalog.module';
import {FavoritesModule} from './core/favorites/favorites.module';
import {ErrorModule} from './core/error-pages/error.module';
import {ItemRequestsModule} from './core/item-requests/item-requests.module';
import {SharedModule} from './core/shared/shared.module';
import {PatronNotificationsModule} from './core/patron-notfications/patron-notifications.module';
import {ForgotPasswordDialogComponent} from './core/login/forgot-password-dialog.component';
import {HttpHeaderInterceptor} from './core/http/http.headers.interceptor';
import {SnackbarNotificationComponent} from './core/shared/snackbar-notification.component';
import {Environment} from './model/environment';
import {MatIconModule, MatIconRegistry} from '@angular/material/icon';
import {DateFnsAdapter} from '@angular/material-date-fns-adapter';
import {enUS} from 'date-fns/locale';
import {PATRON_FEATURE_KEY} from '@store/patron/patron.types';
import {ORGANIZATION_FEATURE_KEY} from '@store/organization/organization.types';
import {organizationState} from '@store/organization/organization.state';
import {BRANCH_FEATURE_KEY} from '@store/branches/branch.types';
import {branchState} from '@store/branches/branch.state';
import {patronState} from '@store/patron/patron.state';
import {EffectsModule} from '@ngrx/effects';
import {OrganizationEffects} from '@store/organization/organization.effects';
import {PatronEffects} from '@store/patron/patron.effects';
import {BranchEffects} from '@store/branches/branch.effects';
import {JwtTokenInterceptor} from './core/http/jwt-token.interceptor';
import {ROUTER_FEATURE_KEY} from '@store/router/router.types';
import {routerReducer, RouterStateSerializer, StoreRouterConnectingModule} from '@ngrx/router-store';
import {CreateAccountDialogComponent} from './core/index/create-account.dialog.component';
import {RouterSerializer} from '@store/router/router.serializer';
import {RvFormsModule} from './core/shared/form-fields/rv-forms.module';
import {QUERY_PARAMS_FEATURE_KEY} from '@store/query-params/query-params.types';
import {queryParamsState} from '@store/query-params/query-params.state';
import {paginationState} from '@store/pagination/pagination.state';
import {PAGINATION_FEATURE_KEY} from '@store/pagination/pagination.types';
import {CHECKOUTS_FEATURE_KEY} from '@store/checkouts/checkouts.types';
import {checkoutsState} from '@store/checkouts/checkouts.state';
import {CheckoutsEffects} from '@store/checkouts/checkouts.effects';
import {PaginationEffects} from '@store/pagination/pagination.effects';
import {QueryParamsEffects} from '@store/query-params/query-params.effects';
import {RouterEffects} from '@store/router/router.effects';
import {MyCardComponent} from './core/my-card/my-card.component';
import {SelectPipeModule} from './core/shared/pipes/select-pipe/select-pipe.module';
import {ACCOUNT_FEATURE_KEY} from '@store/account/account.types';
import {accountState} from '@store/account/account.state';
import {AccountEffects} from '@store/account/account.effects';

const RV_DATE_FORMATS: MatDateFormats = {
  parse: {dateInput: 'MM/dd/yyyy'},
  display: {
    dateInput: 'MM/dd/yyyy',
    monthYearLabel: 'MMMM yyyy',
    dateA11yLabel: 'MM/dd/yyyy',
    monthYearA11yLabel: 'MM yyyy',
  }
};

@NgModule({
  imports: [
    BrowserModule,
    StoreModule.forRoot({}),
    !environment.production ? StoreDevtoolsModule.instrument({connectInZone: true}) : [], // For Redux Devtools browser plugin
    StoreRouterConnectingModule.forRoot({stateKey: ROUTER_FEATURE_KEY}),
    StoreModule.forFeature(ACCOUNT_FEATURE_KEY, accountState),
    StoreModule.forFeature(BRANCH_FEATURE_KEY, branchState),
    StoreModule.forFeature(CHECKOUTS_FEATURE_KEY, checkoutsState),
    StoreModule.forFeature(ORGANIZATION_FEATURE_KEY, organizationState),
    StoreModule.forFeature(PAGINATION_FEATURE_KEY, paginationState),
    StoreModule.forFeature(PATRON_FEATURE_KEY, patronState),
    StoreModule.forFeature(QUERY_PARAMS_FEATURE_KEY, queryParamsState),
    StoreModule.forFeature(ROUTER_FEATURE_KEY, routerReducer),
    EffectsModule.forRoot(),
    EffectsModule.forFeature([
      AccountEffects,
      BranchEffects,
      CheckoutsEffects,
      OrganizationEffects,
      PaginationEffects,
      PatronEffects,
      QueryParamsEffects,
      RouterEffects,
    ]),
    HttpClientModule,
    // FIREBASE
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireAuthModule,
    AngularFireAuthGuardModule,
    // Google Analytics
    //AngularFireAnalyticsModule,
    //AngularFirePerformanceModule,
    // our stuff
    CatalogModule,
    PatronNotificationsModule,
    ItemRequestsModule,
    HoldsModule,
    CheckoutsModule,
    RegistrationModule,
    ProfileModule,
    FavoritesModule,
    RoutesModule,
    AppRoutingModule,
    ErrorModule, // global routing
    SharedModule,
    // Core Modules
    MatIconModule,
    RvFormsModule,
    SelectPipeModule,
  ],
  declarations: [
    AppComponent,
    AppLayoutComponent,
    IndexComponent,
    LandingComponent,
    LoginComponent,
    LogoutComponent,
    ForgotPasswordDialogComponent,
    SnackbarNotificationComponent,
    CreateAccountDialogComponent,
    MyCardComponent,
    // PageNotFoundComponent
  ],
  providers: [
    //PerformanceMonitoringService,
    //UserTrackingService,
    //ScreenTrackingService,
    DatePipe,
    CurrencyPipe,
    TitleCasePipe,
    NonBreakingPipe,
    UpperCasePipe,
    DecimalPipe,
    PercentPipe,
    {provide: Environment, useValue: environment},
    {provide: RouterStateSerializer, useClass: RouterSerializer},
    {provide: HTTP_INTERCEPTORS, useClass: HttpHeaderInterceptor, multi: true},
    {provide: HTTP_INTERCEPTORS, useClass: HttpErrorInterceptor, multi: true},
    {provide: HTTP_INTERCEPTORS, useClass: JwtTokenInterceptor, multi: true},
    {provide: DateAdapter, useClass: DateFnsAdapter},
    {provide: MAT_DATE_FORMATS, useValue: RV_DATE_FORMATS},
    {provide: MAT_DATE_LOCALE, useValue: enUS},
    {
      provide: ErrorHandler,
      // useValue: Sentry.createErrorHandler({showDialog: true}),
    },
    // {provide: Sentry.TraceService, deps: [Router]},
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {
      },
      // deps: [Sentry.TraceService],
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(iconRegistry: MatIconRegistry, domSanitizer: DomSanitizer) {
    iconRegistry.addSvgIconSet(
      domSanitizer.bypassSecurityTrustResourceUrl('./assets/mdi.svg')
    );
  }
}
