import {Component, Input, OnInit} from '@angular/core';
import {BowkerMetadata, CatalogRecord} from '@raven';
import {trackByIndex, TrackByIndexFunction} from '@store/common/track-by.helpers';

@Component({
  selector: 'rn-catalog-details-summary-tab',
  templateUrl: './catalog-details-summary-tab.component.html',
  styleUrls: ['./catalog-details-summary-tab.component.scss'],
})
export class CatalogDetailsSummaryTabComponent implements OnInit {
  otherFormats: Array<object>;
  otherFormatCount = 1;
  meta: object;

  @Input() metadata: BowkerMetadata;
  @Input() catalogRecord: CatalogRecord;

  public readonly trackByIndex: TrackByIndexFunction = trackByIndex;

  constructor() {
  }

  ngOnInit(): void {
    this.setupMockData();
  }

  setupMockData(): void {
    this.meta = {
      thumbnailImageUrl:
        'https://images-na.ssl-images-amazon.com/images/I/81XWWgotqvL.jpg',
      rating: 2.5,
      ratingCount: 88,
      favorite: false,
    };

  }
}
