<div class="page-body-wrapper-with-nav">
  <div class="flex-row">
    <rn-my-account-nav/>
    <div class="balance-and-payments-content">
      <div class="flex-row flex-start-space-between">
        <div>
          <div class="balance-header mat-headline-5">Balance:</div>
          <div class="balance-value mat-headline-4">{{ accountStatusSelectors.currentAccountBalance | select | format: 'CURRENCY' }}</div>
          <div *ngIf="this.balanceExpanded" class="flex-row-col-responsive">
            <div class="balance-detail">
              <div class="mat-subtitle-1">Wallet:</div>
              <div class="mat-headline-6">{{ accountStatusSelectors.walletBalance | select | format: 'CURRENCY' }}</div>
            </div>
            <div class="balance-detail">
              <div class="mat-subtitle-1">Deposit:</div>
              <div class="mat-headline-6">{{ accountStatusSelectors.depositBalance | select | format: 'CURRENCY' }}</div>
            </div>
          </div>
          <a class="flex-row flex-center-start" (click)="toggleExpandedBalance()">See {{ this.balanceExpanded ? 'Less' : 'More' }}
            <span class="expand-symbol mat-headline-5">&nbsp;{{ this.balanceExpanded ? '-' : '+' }}</span>
          </a>
        </div>
        <!--        <ng-container *ngIf="{hasBalance:accountStatusSelectors.hasBalance | select} as vm">-->
        <!--          <button (click)="makeAPayment()" [disabled]="!vm.hasBalance"-->
        <!--                  [matTooltip]="vm.hasBalance ? '' : 'There are no charges on your account to pay'"-->
        <!--                  class="payment-button save-button-group save-button"-->
        <!--                  color="primary" mat-raised-button>-->
        <!--            Make A Payment-->
        <!--          </button>-->
        <!--        </ng-container>-->
      </div>
      <div class="account-activity-header mat-headline-5">Account Activity</div>
      <div class="mobile-table">
        <ng-container *ngIf="{transactions: balanceTransactionSelectors.transactions | select:[]} as vm">
          <div *ngFor="let transaction of vm.transactions; let isLast = last; trackBy: trackTransactionsById">
            <div class="flex-row flex-start-space-between">
              <div class="mobile-table-entry flex-col">
                <rn-transaction-combined-name-item-title [transaction]="transaction"/>
                <div class="mat-caption">{{ balanceTransactionSelectors.idFor(transaction) | select }}</div>
                <div class="mat-caption">{{ balanceTransactionSelectors.createdFor(transaction) | select | format: 'DATE_ONLY' }}</div>
                <div class="mat-caption">Amount:
                  <span [class.text-success-2]="balanceTransactionSelectors.hasBalanceReducingTransactionTypeFor(transaction) | select">
                    {{ balanceTransactionSelectors.amountFor(transaction) | select | format: 'POSITIVE_CURRENCY' }}
                  </span>
                </div>
              </div>
              <div class="mobile-table-right-column flex-col flex-end-space-between">
                <div class="amount-due-container" *ngIf="{
                amountDue: balanceTransactionSelectors.amountOutstandingFor(transaction) | select,
                type: balanceTransactionSelectors.typeFor(transaction) | select,
                } as vm">
                  <ng-container *ngIf="vm.amountDue <= 0 && vm.type !== 'PAY_FROM_EXTERNAL'">
                    <div class="mat-body-1">Amount Due:</div>
                    <div class="mat-caption" [class.text-error]="balanceTransactionSelectors.amountOutstandingIsNegativeFor(transaction) | select">
                      {{ balanceTransactionSelectors.amountOutstandingFor(transaction) | select | format: 'POSITIVE_CURRENCY' }}
                    </div>
                  </ng-container>
                </div>
                <button class="square-icon-button flex-row flex-center-center"
                        *ngIf="balanceTransactionSelectors.showExpansionFor(transaction) | select"
                        (click)="toggleExpandedMobileRow(transaction.id)">
                  <mat-icon>{{ mobileExpandedTransactionId === transaction.id ? 'remove' : 'add' }}</mat-icon>
                </button>
              </div>
            </div>
            <ng-container *ngIf="mobileExpandedTransactionId === transaction.id">
              <ng-container *ngIf="balanceTransactionSelectors.showDetailsExpansionFor(transaction) | select">
                <div class="mat-body-1 mobile-subheader">Details</div>
                <rn-expanded-transaction-opac-note [transaction]="transaction"/>
              </ng-container>
              <ng-container *ngIf="balanceTransactionSelectors.showAmountsExpansionFor(transaction) | select">
                <div class="mat-body-1 mobile-subheader">Description</div>
                <div class="flex-row flex-start-space-between">
                  <rn-expanded-transaction-names [transaction]="transaction"/>
                  <rn-expanded-transaction-amounts [transaction]="transaction"/>
                </div>
              </ng-container>
            </ng-container>
            <mat-divider class="mobile-table-divider" *ngIf="!isLast"/>
          </div>
          <div *ngIf="vm.transactions.length === 0" class="no-items">No items to show</div>
        </ng-container>
        <div class="flex flex-row flex-center-center mobile-show-more" *ngIf="paginationSelectors.onFinalPage | select">
          <ng-container *ngIf="balanceTransactionSelectors.includeAllTransactions | select else showMore">
            <span>End of all activity.</span>
            <button mat-button (click)="toggleAllTransactions()">
              Hide Older Activity
            </button>
          </ng-container>
          <ng-template #showMore>
            <span>Older activity may be hidden.</span>
            <button mat-button (click)="toggleAllTransactions()">
              Show All
            </button>
          </ng-template>
        </div>
        <rn-pagination [class.loaded]="!balanceTransactionSelectors.awaitingAnyLoad"/>
      </div>
      <div class="full-table">
        <rn-table [tableDefinition]="tableDefinition"/>
      </div>
    </div>
  </div>
</div>
