import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {PatronBalanceTransaction} from '@store/account/account.types';
import {AccountSelectors} from '@store/store.selectors';

@Component({
  selector: 'rn-transaction-combined-name-item-title',
  styles: [`
      div.mat-body-1 {
          margin-bottom: 0;
      }
  `],
  template: `
    <div class="flex-col mat-body-1">
      <ng-container *ngIf="transactionSelectors.showAmountsExpansionFor(transaction) | select else description">
        Multiple
      </ng-container>
      <ng-template #description>
        <div>{{ transactionSelectors.nameFor(transaction) | select }}</div>
        <div>{{ transactionSelectors.itemTitleFor(transaction) | select }}</div>
      </ng-template>
    </div>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TransactionCombinedNameItemTitleComponent {
  @Input() transaction: PatronBalanceTransaction;
  public readonly transactionSelectors = AccountSelectors.BalanceTransactions;
}
