import {inject, Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {catchError, map, mergeMap, of, switchMap} from 'rxjs';
import {whenRouterHasNavigatedTo, withLatestFromSelectors} from '@store/common/effects.helpers';
import {AccountSelectors, RouterSelectors} from '@store/store.selectors';
import {AccountActions} from './account.actions';
import {AccountService} from './account.service';
import {RouterActions} from '@store/router/router.actions';
import {PatronActions} from '@store/patron/patron.actions';

@Injectable({providedIn: 'root'})
export class AccountEffects {

  private readonly actions: Actions = inject(Actions);
  private readonly service: AccountService = inject(AccountService);

  public redirectToFirstTabOnRouterNavigated = createEffect(() =>
    this.actions.pipe(
      whenRouterHasNavigatedTo('PROFILE'),
      map(() => RouterActions.replaceRouteWith('PROFILE_ACCOUNT'))
    )
  );

  public loadBalanceInfoAndTransactionsOnRouterNavigated = createEffect(() =>
    this.actions.pipe(
      whenRouterHasNavigatedTo('PROFILE_BALANCE'),
      mergeMap(() => [
        AccountActions.loadBalanceTransactions(),
        PatronActions.loadAccountStatus()
      ])
    )
  );

  public loadBalanceTransactions = createEffect(() =>
    this.actions.pipe(
      ofType(AccountActions.loadBalanceTransactions),
      withLatestFromSelectors(
        RouterSelectors.queryParams,
        AccountSelectors.BalanceTransactions.includeAllTransactions
      ),
      switchMap(([, queryParams, includeAllTransactions]) => this.service.loadBalanceTransactions(queryParams, includeAllTransactions).pipe(
        map(response => AccountActions.loadBalanceTransactionsSuccess(response)),
        catchError((error: unknown) => of(AccountActions.loadBalanceTransactionsFailure(error)))
      ))
    )
  );

  public loadBalanceTransactionsOnToggleIncludeAll = createEffect(() =>
    this.actions.pipe(
      ofType(AccountActions.toggleIncludeAllBalanceTransactions),
      map(() => AccountActions.loadBalanceTransactions())
    )
  );

}
