import {ChangeDetectionStrategy, Component, inject, TrackByFunction} from '@angular/core';
import {AccountSelectors, PaginationSelectors, PatronSelectors} from '@store/store.selectors';
import {PatronBalanceTransaction} from '@store/account/account.types';
import {trackById} from '@store/common/track-by.helpers';
import {CURRENT_ROW_OBJECT, EMPTY_EXPANSION_CELL, TableDefinition} from '../../../table/table.component.types';
import {createSelector, Store} from '@ngrx/store';
import {AccountActions} from '@store/account/account.actions';
import {ExpandedTransactionAmountsComponent} from './expanded-transaction-amounts/expanded-transaction-amounts.component';
import {ExpandedTransactionNamesComponent} from './expanded-transaction-names/expanded-transaction-names.component';
import {ExpandedTransactionOpacNoteComponent} from './expanded-transaction-opac-note/expanded-transaction-opac-note.component';
import {TransactionCombinedNameItemTitleComponent} from './transaction-combined-name-item-title/transaction-combined-name-item-title.component';

@Component({
  selector: 'rn-balance-and-payments',
  templateUrl: './balance-and-payments.component.html',
  styleUrls: ['./balance-and-payments.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BalanceAndPaymentsComponent {
  public readonly balanceTransactionSelectors = AccountSelectors.BalanceTransactions;
  public readonly accountStatusSelectors = PatronSelectors.AccountStatus;
  public readonly paginationSelectors = PaginationSelectors;
  public readonly trackTransactionsById: TrackByFunction<PatronBalanceTransaction> = trackById;
  public balanceExpanded = false;
  public mobileExpandedTransactionId: number | undefined;

  public readonly tableDefinition: TableDefinition<PatronBalanceTransaction, typeof AccountSelectors.BalanceTransactions> = {
    defaultSelectorContainer: this.balanceTransactionSelectors,
    dataSource: 'transactions',
    expansionCells: [
      EMPTY_EXPANSION_CELL,
      EMPTY_EXPANSION_CELL,
      {
        type: 'component',
        header: 'Details:',
        componentClass: ExpandedTransactionOpacNoteComponent,
        componentProperties: {
          transaction: CURRENT_ROW_OBJECT
        },
        showIf: AccountSelectors.BalanceTransactions.showDetailsExpansionFor,
      },
      {
        type: 'component',
        header: 'Description:',
        componentClass: ExpandedTransactionNamesComponent,
        componentProperties: {
          transaction: CURRENT_ROW_OBJECT
        },
        showIf: AccountSelectors.BalanceTransactions.showAmountsExpansionFor,
      },
      {
        type: 'component',
        header: 'Amount:',
        componentClass: ExpandedTransactionAmountsComponent,
        componentProperties: {
          transaction: CURRENT_ROW_OBJECT
        },
        showIf: AccountSelectors.BalanceTransactions.showAmountsExpansionFor,
      },
    ],
    showExpansionControlIf: AccountSelectors.BalanceTransactions.showExpansionFor,
    columns: [
      {
        type: 'text',
        value: 'createdFor',
        header: 'Date',
        format: 'DATE_ONLY',
        headerCssClasses: 'table-column-width-small',
      },
      {
        type: 'text',
        value: 'idFor',
        header: 'Transaction ID',
        headerCssClasses: 'table-column-width-medium',
      },
      {
        type: 'component',
        columnName: 'description',
        componentClass: TransactionCombinedNameItemTitleComponent,
        componentProperties: {
          transaction: CURRENT_ROW_OBJECT
        }
      },
      {
        type: 'text',
        value: 'amountFor',
        header: 'Amount',
        format: 'POSITIVE_CURRENCY',
        headerCssClasses: 'table-column-width-lg',
        cssClasses: {
          'text-success-2': 'hasBalanceReducingTransactionTypeFor'
        }
      },
      {
        type: 'text',
        value: balanceTransaction => createSelector(
          AccountSelectors.BalanceTransactions.amountOutstandingFor(balanceTransaction),
          AccountSelectors.BalanceTransactions.typeFor(balanceTransaction),
          (amountOwed, type) => amountOwed <= 0 && type !== 'PAY_FROM_EXTERNAL' ? amountOwed : ''
        ),
        header: 'Amount Due',
        format: 'POSITIVE_CURRENCY',
        headerCssClasses: 'table-column-width-medium',
        cssClasses: {
          'text-error': 'amountOutstandingIsNegativeFor'
        }
      },
    ],
    hiddenDataIndicatorRow: {
      showIf: PaginationSelectors.onFinalPage,
      message: createSelector<object, boolean, string>(
        AccountSelectors.BalanceTransactions.includeAllTransactions,
        includeAll => includeAll ? 'End of all activity.' : 'Older activity may be hidden.'
      ),
      actionButtonLabel: createSelector<object, boolean, string>(
        AccountSelectors.BalanceTransactions.includeAllTransactions,
        includeAll => includeAll ? 'Hide Older Activity' : 'Show All'
      ),
      clickActionCreator: AccountActions.toggleIncludeAllBalanceTransactions
    }
  };

  public toggleExpandedBalance(): void {
    this.balanceExpanded = !this.balanceExpanded;
  }

  public toggleExpandedMobileRow(id: number): void {
    this.mobileExpandedTransactionId = this.mobileExpandedTransactionId === id ? undefined : id;
  }

  public toggleAllTransactions(): void {
    this.store.dispatch(AccountActions.toggleIncludeAllBalanceTransactions());
  }

  private readonly store = inject(Store);
}
