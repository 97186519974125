import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {PatronBalanceTransaction} from '@store/account/account.types';
import {AccountSelectors} from '@store/store.selectors';

@Component({
  selector: 'rn-expanded-transaction-opac-note',
  template: `
    <div class="expansion-row-content">
      {{ transactionSelectors.opacNoteFor(transaction) | select }}
    </div>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ExpandedTransactionOpacNoteComponent {

  @Input() transaction: PatronBalanceTransaction;

  public readonly transactionSelectors = AccountSelectors.BalanceTransactions;
}
