import {Component, DestroyRef, inject, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {MatDialog} from '@angular/material/dialog';
import {filter} from 'rxjs';
import {Patron, ThemeService} from '@raven';
import {AddressChangeDialog} from './dialogs/address-change.dialog';
import {PasswordUpdateDialog} from './dialogs/password-update-dialog';
import {PinUpdateDialog} from './dialogs/pin-update-dialog';
import {EmailUpdateDialog} from './dialogs/email-update-dialog';
import {map, tap} from 'rxjs/operators';
import {CommonValidators} from '@store/common/common.validators';
import {BranchSelectors, PatronSelectors} from '@store/store.selectors';
import {snapshot} from '@store/store.helpers';
import {PatronService} from '../../../services/patron.service';
import {Store} from '@ngrx/store';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {STATE_SELECT} from '@store/common/common.types';
import {SaveHistoryDialogComponent} from './dialogs/save-history.dialog';
import {DeleteHistoryDialogComponent} from './dialogs/delete-history.dialog';

@Component({
  selector: 'rn-account',
  templateUrl: './account.component.html',
  styleUrls: ['./account.component.scss'],
})
export class AccountComponent implements OnInit {
  public readonly branchSelectors = BranchSelectors.List;
  public readonly patronSelector = PatronSelectors.Auth.patron;
  private readonly destroyRef = inject(DestroyRef);
  protected readonly STATE_SELECT = STATE_SELECT;

  patron: Patron = {
    ...snapshot(this.patronSelector),
    category: undefined,
    patronRule: undefined
  } as Patron;
  personalInfoForm: FormGroup;

  constructor(
    private patronService: PatronService,
    public themeService: ThemeService,
    private fb: FormBuilder,
    private dialog: MatDialog,
    private store: Store,
  ) {
  }

  ngOnInit(): void {
    this.createRegistrationForm();
  }

  createRegistrationForm(): void {
    if (!this.patron.addresses || this.patron.addresses.length == 0) {
      this.patron.addresses = [
        {
          address: '',
          address2: '',
          city: '',
          state: '',
          zipCode: '',
        },
      ];
    }

    this.personalInfoForm = this.fb.group({
      firstName: [this.patron.firstName, {validators: [Validators.required, Validators.maxLength(50)]}],
      lastName: [this.patron.lastName, {validators: [Validators.required, Validators.maxLength(50)]}],
      email: [{value: this.patron.email, disabled: true}],
      password: [{value: '****************', disabled: true}],
      pin: [{value: '****', disabled: true}],
      address: [this.patron.address, {validators: [Validators.required, Validators.minLength(3), Validators.maxLength(100)]},],
      address2: [this.patron.address2, Validators.maxLength(100)],
      city: [this.patron.city, {validators: [Validators.required, Validators.minLength(3), Validators.maxLength(20)]},],
      state: [this.patron.state, {validators: [Validators.required]},],
      zip: [this.patron.zipCode, {validators: [Validators.required, Validators.pattern('^[0-9]{5}(?:-[0-9]{4})?$')]},],
      phone: [this.patron.phone1, [CommonValidators.phoneNumber]],
      barcode: [{value: this.getRedactedCardNumber(), disabled: true}],
      defaultBranch: [this.patron.branchId, {validators: [Validators.required]},],
      optIn: [this.patron.optInReadingHistory],
      theme: [this.themeService.currentTheme]
    });

    this.store.select(PatronSelectors.Auth.patron).pipe(
      map((patron) => ({
        ...patron,
        category: undefined,
        patronRule: undefined
      } as Patron)),
      tap((patronResponse: Patron) => {
        this.personalInfoForm.controls['email'].setValue(patronResponse.email);

        if (patronResponse && patronResponse.address) {
          // emitEvent: true is the default...
          this.personalInfoForm.controls['address'].setValue(patronResponse.address);
          this.personalInfoForm.controls['address2'].setValue(patronResponse.address2);
          this.personalInfoForm.controls['city'].setValue(patronResponse.city);
          this.personalInfoForm.controls['state'].setValue(patronResponse.state);
          this.personalInfoForm.controls['zip'].setValue(patronResponse.zipCode);
        }
      }),
      takeUntilDestroyed(this.destroyRef));
    this.personalInfoForm.get('phone').valueChanges.subscribe(phone => {
      // get only the numeric characters
      phone = phone.replace(/\D/g, '');
      // the formcontrol.valueChanges fires before the values on the form object update, allowing us to get the previous value
      const oldValue = this.personalInfoForm.value.phone.replace(/\D/g, '');
      if (phone.length != 10 && phone == oldValue) {
        // this allows the user to backspace without automatically re-adding characters
        return;
      }
      // format to (###) ###-####
      let newPhone = `(${phone.slice(0, 3)}`;
      if (phone.length > 2) {
        newPhone += `) ${phone.slice(3, 6)}`;
      }
      if (phone.length > 5) {
        newPhone += `-${phone.slice(6, 10)}`;
      }
      this.personalInfoForm.get('phone').setValue(newPhone, {emitEvent: false});
    });
  }

  getRedactedCardNumber(): string {
    return this.patron.barcode?.replace(/.(?=.{4,}$)/g, '*');
  }

  submit(showAddressDialog = true, showOptInDialog = true): void {
    if (showAddressDialog && this.isAddressChanged()) {
      const dialogRef = this.dialog.open(AddressChangeDialog, {maxWidth: '95vw',});
      dialogRef
        .afterClosed()
        .pipe(
          filter(confirmation => confirmation),
          takeUntilDestroyed(this.destroyRef)
        )
        .subscribe(() => this.submit(false));
      return;
    }
    if (showOptInDialog && this.isReadingHistoryPreferenceChanged()) {
      const dialogRef = this.dialog.open(
        this.personalInfoForm.value.optIn ?
          SaveHistoryDialogComponent : DeleteHistoryDialogComponent, {maxWidth: '95vw',});
      dialogRef
        .afterClosed()
        .pipe(
          filter(confirmation => confirmation),
          takeUntilDestroyed(this.destroyRef)
        )
        .subscribe(() => this.submit(false, false));
      return;
    }
    this.save();
  }

  isAddressChanged(): boolean {
    const formModel = this.personalInfoForm.value;
    return this.patron.address != formModel.address ||
      this.patron.address2 != formModel.address2 ||
      this.patron.city != formModel.city ||
      this.patron.state != formModel.state ||
      this.patron.zipCode != formModel.zip;
  }

  isReadingHistoryPreferenceChanged(): boolean {
    const formModel = this.personalInfoForm.value;
    return this.patron.optInReadingHistory != formModel.optIn;
  }

  save(): void {
    const formModel = this.personalInfoForm.value;
    this.patron.firstName = formModel.firstName;
    this.patron.lastName = formModel.lastName;
    this.patron.address = formModel.address;
    this.patron.address2 = formModel.address2;
    this.patron.city = formModel.city;
    this.patron.state = formModel.state;
    this.patron.zipCode = formModel.zip;
    this.patron.phone1 = formModel.phone;
    // this.patron.country = 'United States of America';
    this.patron.branchId = formModel.defaultBranch;
    this.patron.optInReadingHistory = formModel.optIn;

    this.patronService.save(this.patron, {
      errorMessage: 'Error updating your personal information. Please contact customer support.',
      successMessage: 'Personal information updated.',
      onSuccess: rb => {
        this.patron = rb.objects[0];
        this.personalInfoForm.markAsPristine();
        this.personalInfoForm.markAsUntouched();
      }
    })
  }

  updateEmail(): void {
    this.dialog.open(EmailUpdateDialog);
  }

  updatePassword(): void {
    this.dialog.open(PasswordUpdateDialog);
  }

  updatePin(): void {
    this.dialog.open(PinUpdateDialog)
      .afterClosed()
      .subscribe((updatedConfirmation) => {
        if (updatedConfirmation) {
          this.patron.pinGenerated = false;
        }
      });
  }

  undo(): void {
    this.personalInfoForm.reset();
    this.personalInfoForm.setValue({
      firstName: this.patron.firstName,
      lastName: this.patron.lastName,
      email: this.patron.email,
      password: '****************',
      pin: '****',
      address: this.patron.address,
      address2: this.patron.address2,
      city: this.patron.city,
      state: this.patron.state,
      zip: this.patron.zipCode,
      phone: this.patron.phone1,
      barcode: this.getRedactedCardNumber(),
      defaultBranch: this.patron.branchId,
      optIn: this.patron.optInReadingHistory,
      theme: this.themeService.currentTheme
    });
  }

}
